<template>
  <div class="clientsList">
    <mini-loader v-if="load" />
    <div class="card" style="overflow-x: auto; max-width: 100%">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Все клиенты</h5>
        <a @click="createItem">
          <span class=" p-1">
            <button type="button" class="btn btn-success" >Добавить
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>

          </span>
        </a>
      </div>
      <table class="table table-striped" id="usersList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Лого</th>
            <th scope="col">Имя</th>
            <th scope="col">Email</th>
            <th scope="col">Телефон</th>
            <th scope="col">Тип</th>
            <th scope="col">Клиент офиса</th>
            <th scope="col">Создатель</th>
            <th scope="col">Баланс</th>
            <th scope="col">Статус</th>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td><div class="my-img-circle"> <img v-if="value.image" class="img-fluid" :src="$store.state.urlBackend + '/' + getImage(value.image)" alt=""> <img class="img-fluid" v-else width="100" :src="$store.state.emptyClient" alt=""> </div></td>
            <td>{{value.name}}</td>
            <td>{{value.email}}</td>
            <td>
              {{value.phone}} <br>
              <div style="color: #18D300; text-transform: uppercase" v-if="value.phone_verified_at">
                Подтвержден
              </div>
              <div style="color: red; text-transform: uppercase" v-else>
                Не подтвержден
              </div>
            </td>
            <td> <div v-if="value.type == 'individual'" style="color: blue"> Физ.лицо</div> <div v-else style="color: green">Юр.лицо</div> </td>
            <td><div v-if="value.branch" style="color: #8269FF; cursor: pointer">{{value.branch.title}}</div></td>
            <td><div v-if="value.creator" style="color: #8269FF; cursor: pointer">{{value.creator.name}}</div></td>
            <td><div v-if="value.balance <= 0" style="color: red">{{value.balance}} uzs</div> <div v-else>{{ value.balance }} uzs</div></td>
            <td><span class="btn btn-success" v-if="value.status == 'active'">Активный</span> <span class="btn btn-danger" v-else>Неактивный</span> </td>
            <td style="display: flex">
              <a  @click="showBalance(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                    <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z"/>
                  </svg>
                </span>
              </a>
              <a @click="editItem(value, index)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteItem(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Create modal -->
    <a-modal v-model="visibleCreateModal" title="Добавить клиента"  footer="">

      <div class="mb-3">
        <label for="">Имя <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Имя" v-model="newItem.name" :class="{'is-invalid': validateErrors.name}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.name"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Email <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Email" v-model="newItem.email" :class="{'is-invalid': validateErrors.email}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.email"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Пароль <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Пароль" v-model="newItem.password" :class="{'is-invalid': validateErrors.password}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.password"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Телефон <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Телефон" v-model="newItem.phone" :class="{'is-invalid': validateErrors.phone}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3">
        <label for="">Тип клиента <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Тип клиентя"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.type"
          :allowClear="true"
          :filter-option="filterOption"
          :class="{'is-invalid': validateErrors.type}"
        >
          <a-select-option v-for="ty in type" :key="ty.value" :value="ty.value" :label="ty.title">
            {{ty.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.type"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Эксклюзив? Вип?</label>
        <a-select
          show-search
          placeholder="Тип клиентя"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.second_type"
          :allowClear="true"
          :filter-option="filterOption"
          :class="{'is-invalid': validateErrors.second_type}"
        >
          <a-select-option v-for="ty in second_type" :key="ty.value" :value="ty.value" :label="ty.title">
            {{ty.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.second_type"> {{value}} </div>
      </div>

      <div class="mb-3">
        <label for="">Страна   <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите страну"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.country_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchCountries"
          @change="changeCountries"
          :class="{'is-invalid': validateErrors.country_id}"
        >
          <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
            {{country.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Область  <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите область"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.region_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchRegions"
          @change="changeRegions"
          :class="{'is-invalid': validateErrors.region_id}"
        >
          <a-select-option v-for="region in regions" :key="region.id" :value="region.id" :label="region.title">
            {{region.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Район  <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Выберите район"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.city_id"
          :allowClear="true"
          :filter-option="filterOption"
          @search="searchCities"
          @change="changeCities"
          :class="{'is-invalid': validateErrors.city_id}"
        >
          <a-select-option v-for="city in cities" :key="city.id" :value="city.id" :label="city.title">
            {{city.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.city_id"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Адрес </label>
        <template>
          <a-input placeholder="Адрес" v-model="newItem.address" :class="{'is-invalid': validateErrors.address}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Почтовый индекс</label>
        <template>
          <a-input placeholder="Почтовый индекс" v-model="newItem.post_index" :class="{'is-invalid': validateErrors.post_index}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.post_index"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3" v-if="me.roles[0].name == 'admin' || me.roles[0].name == 'moderator'">
        <label for="">Клиент какого офиса? </label>
        <a-select
          show-search
          placeholder="Выберите офис"
          option-filter-prop="children"
          style="width: 100%"
          v-model="newItem.branchoffice_id"
          :allowClear="true"
          :filter-option="filterOption"
          :class="{'is-invalid': validateErrors.branchoffice_id}"
        >
          <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
            <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
      </div>

      <div class="form-group">
        <label for="exampleInputEmail">Статус пользователя <span :style="{color: status == 'Активный' ? '#2EB85C' : 'red'}" >({{status}})</span> </label>
        <a-form-item>
          <a-switch v-decorator="['switch', { valuePropName: 'active' }]" @change="changeSwitchItem" />
        </a-form-item>
        <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
      </div>

      <div :class="{'is-invalid': validateErrors.image}">
        <div v-if="!file">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange" >
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title">Перетащите файл сюда</span>
                <div class="dropZone-upload-limit-info">
                  <div>Поддерживаемые расширения: png, jpg, jpeg, bmp</div>
                  <div>Максимальный размер изображения: 5МБ</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>
          <div v-else class="dropZone-uploaded">
            <div class="dropZone-uploaded-info">
              <span class="dropZone-title">Uploaded</span>
              <button type="button" class="btn btn-primary removeFile" @click="removeFile">Remove File</button>
            </div>
          </div>
      </div>
      <div class="invalid-feedback" v-for="value in validateErrors.image"> {{value}} </div>

      <br>
      <div class="d-flex">
        <button type="submit" class="ml-auto btn btn-success" @click="storeItem">Добавить</button>
      </div>
    </a-modal>

    <!-- Edit modal -->
    <a-modal v-model="visibleEditModal" title="Изменить данные  клиента"  footer="">

      <div class="mb-3">
        <label for="">Имя <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Имя" v-model="item.name" :class="{'is-invalid': validateErrors.name}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.name"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Email <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Email" v-model="item.email" :class="{'is-invalid': validateErrors.email}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.email"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Пароль <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Пароль" v-model="item.password" :class="{'is-invalid': validateErrors.password}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.password"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Телефон <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Телефон" v-model="item.phone" :class="{'is-invalid': validateErrors.phone}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
        </template>
      </div>

      <div class="mb-3">
        <label for="">Тип клиента <span style="color: #e55353">*</span></label>
        <a-select
          show-search
          placeholder="Тип клиентя"
          option-filter-prop="children"
          style="width: 100%"
          v-model="item.type"
          :allowClear="true"
          :filter-option="filterOption"
          :class="{'is-invalid': validateErrors.type}"
        >
          <a-select-option v-for="ty in type" :key="ty.value" :value="ty.value" :label="ty.title">
            {{ty.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.type"> {{value}} </div>
      </div>
      <div class="mb-3">
        <label for="">Эксклюзив? Вип?</label>
        <a-select
          show-search
          placeholder="Тип клиентя"
          option-filter-prop="children"
          style="width: 100%"
          v-model="item.second_type"
          :allowClear="true"
          :filter-option="filterOption"
          :class="{'is-invalid': validateErrors.second_type}"
        >
          <a-select-option v-for="ty in second_type" :key="ty.value" :value="ty.value" :label="ty.title">
            {{ty.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.second_type"> {{value}} </div>
      </div>

      <div class="" v-if="editCountry">
        <div class="mb-3">
          <label for="">Страна   <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите страну"
            option-filter-prop="children"
            style="width: 100%"
            v-model="item.country_id"
            :allowClear="true"
            :filter-option="filterOption"
            @search="searchCountries"
            @change="changeCountries"
            :class="{'is-invalid': validateErrors.country_id}"
          >
            <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
              {{country.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
        </div>
        <div class="mb-3">
          <label for="">Область  <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите область"
            option-filter-prop="children"
            style="width: 100%"
            v-model="item.region_id"
            :allowClear="true"
            :filter-option="filterOption"
            @search="searchRegions"
            @change="changeRegions"
            :class="{'is-invalid': validateErrors.region_id}"
          >
            <a-select-option v-for="region in regions" :key="region.id" :value="region.id" :label="region.title">
              {{region.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
        </div>
        <div class="mb-3">
          <label for="">Район  <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите район"
            option-filter-prop="children"
            style="width: 100%"
            v-model="item.city_id"
            :allowClear="true"
            :filter-option="filterOption"
            @search="searchCities"
            @change="changeCities"
            :class="{'is-invalid': validateErrors.city_id}"
          >
            <a-select-option v-for="city in cities" :key="city.id" :value="city.id" :label="city.title">
              {{city.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.city_id"> {{value}} </div>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="mb-3">
            <label for="">Страна <span style="color: #e55353">*</span></label>
            <template v-if="item.country">
              <a-input placeholder="Страна" v-model="item.country.title" disabled/>
            </template>
          </div>
          <div class="mb-3">
            <label for="">Область <span style="color: #e55353">*</span></label>
            <template v-if="item.region">
              <a-input placeholder="Страна" v-model="item.region.title" disabled/>
            </template>
          </div>
          <div class="mb-3" >
            <label for="">Район <span style="color: #e55353">*</span></label>
            <div class="d-flex">
              <template v-if="item.city">
                <a-input placeholder="Район" v-model="item.city.title" disabled/>
              </template>
              <a class="btn btn-primary" style="color: #fff" @click="editCountry = true; item.country_id=''; item.region_id=''; item.city_id='';">Изменить</a>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="">Адрес </label>
        <template>
          <a-input placeholder="Адрес" v-model="item.address" :class="{'is-invalid': validateErrors.address}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Почтовый индекс</label>
        <template>
          <a-input placeholder="Почтовый индекс" v-model="item.post_index" :class="{'is-invalid': validateErrors.post_index}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.post_index"> {{value}} </div>
        </template>
      </div>

      <!-- <div class="mb-3" v-if="me.roles[0].name == 'admin' || me.roles[0].name == 'moderator'">
        <label for="">Клиент какого офиса? </label>
        <a-select
          show-search
          placeholder="Выберите офис"
          option-filter-prop="children"
          style="width: 100%"
          v-model="item.branchoffice_id"
          :allowClear="true"
          :filter-option="filterOption"
          :class="{'is-invalid': validateErrors.branchoffice_id}"
        >
          <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
            <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
      </div> -->

      <div class="form-group">
        <label for="exampleInputEmail">Статус пользователя <span :style="{color: status == 'Активный' ? '#2EB85C' : 'red'}" >({{status}})</span> </label>
        <div v-if="item.status == 'active'">
          <div>
            <a-switch default-checked @change="changeSwitchItem" />
          </div>
        </div>
        <div v-else>
          <a-form-item>
            <a-switch v-decorator="['switch', { valuePropName: 'active' }]" @change="changeSwitchItem" />
          </a-form-item>
        </div>
        <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
      </div>

      <div class="clearfix" :class="{'is-invalid': validateErrors.image}">
         <a-upload
           :action="$store.state.urlBackend + '/api/clients/' + item.hash + '/image/upload'"
           :headers='{"Authorization": `Bearer ` + access_token}'
           list-type="picture-card"
           :file-list="fileList"
           :before-upload="beforeUpload"
           @preview="handlePreview"
           @change="imageUploadFunction"
         >
           <div v-if="fileList.length < 1">
             <a-icon type="plus" />
             <div class="ant-upload-text">
               Upload
             </div>
           </div>
         </a-upload>
         <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
           <img alt="example" style="width: 100%" :src="previewImage" />
         </a-modal>
       </div>

      <div class="invalid-feedback" v-for="value in validateErrors.image"> {{value}} </div>

      <br>
      <div class="d-flex">
        <button type="submit" class="ml-auto btn btn-success" @click="updateItem">Сохранить</button>
      </div>
    </a-modal>

    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" footer="">
      <span style="color: red; ">Удаление клиента не доступно</span>
    </a-modal>

    <!-- balance show modal -->
    <a-modal v-model="visibleShowBalanceModal" footer="">
      <div>
        <div class="mb-3">
          <label for="">Сумма <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="Сумма" v-model="transaction.amount" :class="{'is-invalid': validateErrors.amount}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.amount"> {{value}} </div>
          </template>
        </div>
        <div class="mb-3">
          <label for="">Комментарие <span style="color: #e55353">*</span></label>
          <template>
            <a-input placeholder="Комментарие" v-model="transaction.comment" :class="{'is-invalid': validateErrors.comment}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.comment"> {{value}} </div>
          </template>
        </div>
        <div class="d-flex">
          <button type="submit" class="ml-auto btn btn-success" @click="storeTransaction">Добавить</button>
        </div>
      </div>
    </a-modal>

  </div>
</template>

<script>
import axios from 'axios'
const data = '';
export default {
  name: "Clients",
  data() {
    return {
      data,
      item: [],
      oldItem: {
        name: '',
        branchoffice_id: '',
        creator_id: '',
        email: '',
        phone: '',
        status: 'inactive',
        type: 'individual',
        second_type: '',
        country_id: '',
        region_id: '',
        city_id: '',
        address: '',
        post_index: '',
        image: ''
      },
      newItem: {
        name: '',
        branchoffice_id: '',
        creator_id: '',
        email: '',
        phone: '',
        status: 'inactive',
        type: 'individual',
        second_type: '',
        country_id: '',
        region_id: '',
        city_id: '',
        address: '',
        post_index: '',
        image: ''
      },
      transaction: {
        user_id: null,
        amount: null,
        comment: null,
      },

      validateErrors: [],

      file: '',
      dragging: false,
      previewVisible: false,
      previewImage: '',
      access_token: localStorage.token,
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ],

      type: [{title: 'Юридическое лицо', value: 'entity' },{title: 'Физическое лицо',value: 'individual'}],
      second_type: [{title: 'Эксклюзив', value: 'exclusive' },{title: 'Вип',value: 'vip'}],
      countries: [{id: 192, title: 'Узбекистан'}],
      regions: [],
      cities: [],
      branches: [],
      status: 'Неактивный',

      dataFilter: {
        country: '',
        region: ''
      },

      index: '',
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,

      load: true,
      visibleShowBalanceModal: false,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,
      editCountryRegionCity: false,

      editCountry: false,

    }
  },
  mounted(){
    axios.get('/clients').then((response)=>{
      if(response.status == 200){
        this.data = response.data.data;
        this.totalResults = response.data.total;
        this.load = false;
        this.last_page = response.data.last_page;
        console.log(response.data)
      }else if(response.status == 204){
        this.load = false;
        this.$message.error(
              'Клиентов не найдено',
              3,
        );
      }
     }).catch((error)=>{
       if(error.response.status == 403){
         this.$message.error(
               error.response.data.message,
               3,
         );
         this.load = false;
       }else if(error.response.status == 500){
         this.load = false;
         this.$message.error(
               'Ошибка сервера',
               3,
         );
       }
     }).finally(() => {this.load = false});
    this.getBranches();
    window.onscroll = () => {
        let height = document.getElementById('usersList').clientHeight
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            let limit = this.data.length/this.$store.state.paginateCount + 1;
            if(limit <= this.last_page){
              this.load = true;
              axios.get('/clients', {params: {page: limit}}).then(response => {
                if(response.status == 200){
                    this.totalResults = response.data.total;
                    this.data = this.data.concat(response.data.data);
                    this.load = false
                }else if(response.status == 204){
                  this.load = false;
                  this.$message.error(
                    'Клиентов не найдено',
                    3
                  );
                }
              }).catch(error => {
                if(error.response.status == 500){
                  this.load = false;
                  this.$message.error(
                    'Ошибка сервера',
                    3
                  );
                }else if(error.response.status == 204){
                  this.load = false;
                  this.$message.error(
                    'Клиентов не найдено',
                    3
                  );
                }else{
                  this.load = false
                }
              });
            }
          }
        }
    };
  },
  methods: {
    storeTransaction(){
      axios.post('/clients/transactions', this.transaction, {
        headers: {
          'Accept': 'application/json',
        }}).then((response) => {
          if(response.status == 200){
            this.visibleShowBalanceModal = false;
            window.reload()
          }
          console.log(response.data)
        }).catch((error) => {
          if(error.response.status == 422){
            this.validateErrors = error.response.data.errors;
          }
          console.log(error.response.data);
        }).finally(() => {this.load = false})
    },
    showBalance(value){
      this.transaction.user_id = value.id;
      this.visibleShowBalanceModal = true;
    },
    createItem(){
      this.visibleCreateModal = true;

    },
    showItem(value){

    },
    editItem(value, index){
      this.index = index;
      this.fileList = [];
      if(value.image){
        let imageItem = [];
        imageItem.uid = value.id;
        imageItem.name = 'image.png';
        imageItem.status = 'done';
        imageItem.url = this.$store.state.urlBackend + '/' + this.getImage(value.image);
        this.fileList[0] = imageItem;
      }
      this.item = value;
      this.validateErrors = [];
      this.visibleEditModal = true;
      this.editCountry = false;
      if(this.item.status == 'active'){
        this.status = 'Активный';
      }else{
        this.status = 'Неактивный';
      }
    },
    deleteItem(value, index){
      this.visibleDeleteModal = true;
    },

    storeItem(){
      let formData = new FormData();
      formData.append('image', this.file);
      formData.append('name', this.newItem.name);
      formData.append('branchoffice_id', this.newItem.branchoffice_id);
      formData.append('email', this.newItem.email);
      formData.append('phone', this.newItem.phone);
      formData.append('status', this.newItem.status);
      formData.append('type', this.newItem.type);
      formData.append('second_type', this.newItem.second_type);
      formData.append('country_id', this.newItem.country_id);
      formData.append('region_id', this.newItem.region_id);
      formData.append('city_id', this.newItem.city_id);
      formData.append('address', this.newItem.address);
      formData.append('post_index', this.newItem.post_index);
      formData.append('password', this.newItem.password);
      this.load = true;
      console.log('gg')
      axios.post('/clients', formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': `multipart/form-data`,
        }}).then((response) => {
          if(response.status == 201){
            this.data = this.data.concat(response.data);
            this.newItem = this.oldItem;
            this.visibleCreateModal = false;
          }
          console.log(response.data)
        }).catch((error) => {
          if(error.response.status == 422){
            this.validateErrors = error.response.data.errors;
          }
          console.log(error.response.data);
        }).finally(() => {this.load = false})
    },
    updateItem(value){
      this.load = true;
      axios.put('/clients', this.item).then((response) => {
        if(response.status == 200){
          console.log(response.data);
          this.data[this.index] = response.data;
          this.$forceUpdate();
          this.validateErrors = [];
          this.$message.success(
            'Успешно изменено',
            3
          );
          this.visibleEditModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 422){
          this.$message.error(
            'Проверьте правильность данных',
            3
          );
          this.validateErrors = error.response.data.errors;
        }else if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          )
        }else if(error.response.status == 500){
          this.$message.error(
            error.response.data.error,
            3
          )
        }
      }).finally(() => {this.load = false});
    },
    destroyItem(value, index){

    },

    getImage(value){
      return value.charAt(0) === '/' ? value.substr(1) : value;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj);
      // }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('Пожалуйста выберите (png, bmp, jpg, jpeg)!', 3);
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('Размер не должен превышать 2 мб!', 3);
      }
      return isJpgOrPng && isLt2M;
    },
    imageUploadFunction({ fileList }) {
      if(!fileList.length){
        axios.delete('/clients/' + this.item.hash + '/image/delete/').then(response => {
          if(response.status == 200){
            this.$message.success(response.data.success, 3);

            this.item.image = '';
          }else{
            this.$message.error(
              response.data.error,
              3
            );
          }
        }).catch(error => {
          console.log(error);
          if(error.response.status == 500){
            this.$message.error(
              error.response.data.error,
              3
            );
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.fileList = fileList;
        });
      }else{ console.log(fileList[0].response)
        this.fileList = fileList;
        if(fileList[0].response){
          this.item.image = fileList[0].response
        }
      }

    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('image.*')) {
        alert('please select txt file');
        this.dragging = false;
        return;
      }
      if (file.size > 2048000) {
        this.$message.error(
          'Пожалуйста выберите файла размером меньше 2МБ',
          3
        )
        this.dragging = false;
        return;
      }
      this.file = file;
      this.newItem.image = file;
      console.log(this.newItem.image);
      this.dragging = false;
    },
    removeFile() {
      this.file = '';
    },

    changeSwitchItem(value){ console.log(value)
      this.newItem.status = value ? 'active' : 'inactive';
      this.item.status = value ? 'active' : 'inactive';
      this.status = value ? 'Активный' : 'Неактивный';
      console.log(this.user);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getBranches(){
      axios.get('/employee/branches').then(response => {
        if(response.status == 200){
          this.branches = response.data;
        }else{
          this.$message.error(
            'Филиалов не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Филиалов не найдено',
            3
          );
        }
      });
    },
    searchCountries(value){
      axios.get('/employee/countries/search', {params: {query: value}}).then((response) =>  {
        if(response.status == 200){
          this.countries = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующих стран',
            3
          );
        }
      }).catch((error)  =>  {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      }).finally(() => {

      });
    },
    changeCountries(value){
      axios.get('/employee/regions-with-cities/'+value).then((response) => {
        if(response.status == 200){
          this.regions = response.data.regions;
          this.cities = response.data.cities;
          this.dataFilter.country = value;
          this.dataForMap.country = response.data.country.title;
        }
      }).catch((error) => {

      });
    },
    searchRegions(value){
      axios.get('/employee/regions/search', {params: {query: value, country: this.dataFilter.country}}).then((response) => {
        if(response.status == 200){
          this.regions = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующих областей',
            3
          );
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      })
    },
    changeRegions(value){
      axios.get('/employee/cities-by-region/' + value).then((response) => {
        if(response.status == 200){
          this.cities = response.data.cities;
          this.dataFilter.region = value;
          this.dataForMap.region = response.data.region.title;
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      });
    },
    searchCities(value){
      axios.get('/employee/cities/search', {params: {query: value, country: this.dataFilter.country, region: this.dataFilter.region}}).then((response) => {
        if(response.status == 200){
          this.cities = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующих районов',
            3
          );
        }
      }).catch((error)  =>  {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          )
        }
      })
    },
    changeCities(value){

    },

  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    }
  }
}
</script>

<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }

  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .my-img-circle img{
    width: 75px;
  }




  .dropZone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px dotted #2e94c4;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>
